/**
 * 主页的ajax函数
 */

// 引入设置好的axios
import request from '@/utils/request'


// 许愿小程序配置查看
export const getPic = (params) => {
    return request({
        url: '/admin/settingDetails',
        method: "get",
        params
    })
}

// 许愿小程序配置修改
export const setPic = (data) => {
    return request({
        url: '/admin/settingUpdates',
        method: "post",
        data
    })
}

