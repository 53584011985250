<template>
  <div class="shop">
    <el-card class="box-card">
      <!-- 标题 -->
      <div slot="header" class="header">
        <span>小程序配置</span>
      </div>

      <!-- 内容 -->
      <div class="content">
        <el-form ref="form" :model="formData" label-width="80px">
          <!-- banner图片 -->
          <el-form-item label="海报图片">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :headers="data"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img
                v-if="formData.banner"
                :src="baseUrl + formData.banner"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>

            <div style="color: red">海报尺寸5:3 (例如：1000*600)</div>
          </el-form-item>

          <!-- 背景色 -->
          <el-form-item label="背景颜色">
            <el-color-picker
              v-model="formData.background_red"
            ></el-color-picker>
          </el-form-item>

          <!-- 分享标题 -->
          <el-form-item label="分享标题">
            <el-input
              v-model="formData.share_text"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>

          <!-- 分享图片 -->
          <el-form-item label="分享图片">
            <el-upload
              class="avatar-uploader"
              :action="action"
              :headers="data"
              :show-file-list="false"
              :on-success="handleShareSuccess"
            >
              <img
                v-if="formData.banner"
                :src="baseUrl + formData.share_pic"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="color: red">图片尺寸5: 4 (例如：1000*800)</div>
          </el-form-item>

          <el-form-item>
            <el-button @click="save" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
// 工具函数
import local from "@/utils/local";
// 图片访问
import baseUrl from "@/config/index.js";
// 请求
import { getPic, setPic } from "@/api/miniprogram.js";

export default {
  // 数据
  data() {
    return {
      formData: {
        banner: "",
        background_red: "",
        share_text: "",
        share_pic: "",
      },
      baseUrl: baseUrl.baseUrl,
      dialogVisible: false, // 图片预览
      data: {
        token: local.get("token"),
      },
    };
  },

  // 方法
  methods: {
    // 保存修改
    async save() {
      console.log(this.formData);
      let res = await setPic(this.formData);
      if (res.code === 200) {
        this.$message.success("保存成功！");
      } else {
        this.$message.error(res.msg);
      }
    },

    // banner上传
    handleAvatarSuccess(res, file) {
      if (res.code === 200) {
        this.formData.banner = res.data.file_name;
        this.$message.success("上传成功！");
      } else {
        this.$message.error(res.msg);
      }
    },

    // 分享封面上传
    handleShareSuccess(res, file) {
      if (res.code === 200) {
        this.formData.share_pic = res.data.file_name;
        this.$message.success("上传成功！");
      } else {
        this.$message.error(res.msg);
      }
    },

    // 获取数据
    async getData() {
      let res = await getPic();
      if (res.code === 200) {
        this.formData = {
          banner: res.data.banner,
          background_red: res.data.background_red,
          share_pic: res.data.share_pic,
          share_text: res.data.share_text,
        };
      }
    },
  },

  created() {
    this.getData();
  },

  computed: {
    action() {
      let url = window.location.href.split("#")[0];
      if (url.includes("localhost")) {
        return url + "api/admin/uploads";
      } else {
        return url + "admin/uploads";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.shop {
  .header {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
  /* 头像 */
  /deep/.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 300px;
    height: 180px;
    line-height: 180px;
    text-align: center;
  }
  .avatar {
    width: 300px;
    height: 180px;
    display: block;
  }

  /deep/.el-input {
    width: 27% !important;
  }
}
</style>